:root {
    --BK-10000: #17171A;
    --BK-900: #27272C;
    --BK-800: #46464F;
    --BK-700: #656572;
    --BK-600: #747483;
    --BK-500: #9797A3;
    --BK-400: #B9B9C1;
    --BK-300: #CBCBD0;
    --BK-200: #DCDCE0;
    --BK-100: #EEEEF0;
    --primary-color: #C27CDE;
  }
  